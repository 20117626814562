$(document).ready(function () {

    /* ----------------------------------------------------- */
    /* COOKIES */
    /* ----------------------------------------------------- */
    $(document).on('click', '.open-cookies', function(event) {
        event.preventDefault();

        tarteaucitron.userInterface.openPanel();
        return false;
    });

	// $('#cookie_stop').click(function(){
 //        $('#cookie_disclaimer').slideUp();


 //        var nDays = 999;
 //        var cookieName = "disclaimer_galvanoplast";
 //        var cookieValue = "true";

 //        var today = new Date();
 //        var expire = new Date();
 //        expire.setTime(today.getTime() + 3600000*24*nDays);
 //        document.cookie = cookieName+"="+cookieValue+";expires="+expire.toGMTString()+";path=/";
 //        console.log('cookie registred');
 //    });

    $('#zone_orange_area, #zone_orange_claire').hover(function() {

        // var posX = $(this).offset().left + ($(this).innerWidth() / 2) - $('.map_contacts').offset().left;
        // var posY = $(this).offset().top + ($(this).innerHeight() / 2) - $('.map_contacts').offset().top;

        // $('#zone_orange').css({
        //     top: posY,
        //     left: posX
        // }).addClass('visible');

        $('#zone_orange').addClass('visible');

    }, function() {
        $('#zone_orange').removeClass('visible');
    });

    $('#zone_bleue_area, #zone_bleue_claire').hover(function() {

        // var posX = $(this).offset().left + ($(this).innerWidth() / 2) - $('.map_contacts').offset().left;
        // var posY = $(this).offset().top + ($(this).innerHeight() / 2) - $('.map_contacts').offset().top;

        // $('#zone_bleue').css({
        //     top: posY,
        //     left: posX
        // }).addClass('visible');

        $('#zone_bleue').addClass('visible');

    }, function() {
        $('#zone_bleue').removeClass('visible');
    });

    $('#zone_grise_area, #zone_grise_2').hover(function() {

        // var posX = $(this).offset().left + ($(this).innerWidth() / 2) - $('.map_contacts').offset().left;
        // var posY = $(this).offset().top + ($(this).innerHeight() / 2) - $('.map_contacts').offset().top;

        // $('#zone_bleue').css({
        //     top: posY,
        //     left: posX
        // }).addClass('visible');

        $('#zone_grise').addClass('visible');

    }, function() {
        $('#zone_grise').removeClass('visible');
    });


    $(window).scroll(function() {

        /* Check the location of each desired element */
        $('.fadein:not("faded")').each( function(i){

            var bottom_of_object = $(this).offset().top + ($(this).outerHeight()*0.25);
            var bottom_of_window = $(window).scrollTop() + $(window).height();

            /* If the object is completely visible in the window, fade it it */
            if( bottom_of_window > bottom_of_object || (bottom_of_window - $(this).offset().top) > 300  ){
                $(this).addClass('faded');
                $(this).css('opacity', '1');
                $(this).css('top', '0');
                $(this).css('right', '0');
                $(this).css('left', '0');
            }
        });


        /* Check the location of each desired element */
        $('.fadein.faded').not('.notinfade').each( function(i){
            var bottom_of_object = $(this).offset().top + ($(this).outerHeight()*0.25);
            var bottom_of_window = $(window).scrollTop() + $(window).height();

            /* If the object is completely visible in the window, fade it it */
            if( bottom_of_window < bottom_of_object ){
                $(this).removeClass('faded');
                $(this).removeAttr('style');
            }
        });
    });

    /* Check the location of each desired element */
    $('.fadein:not("faded")').each( function(i){
        var bottom_of_object = $(this).offset().top + ($(this).outerHeight()*0.5);
        var bottom_of_window = $(window).scrollTop() + $(window).height();

        /* If the object is completely visible in the window, fade it it */
        if( bottom_of_window > bottom_of_object || (bottom_of_window - $(this).offset().top) > 200  ){
            $(this).addClass('faded');
            $(this).css('opacity', '1');
            $(this).css('top', '0');
            $(this).css('right', '0');
            $(this).css('left', '0');
        }
    });


    /* ----------------------------------------------------- */
    /* SLIDER */
    /* ----------------------------------------------------- */
    $('.slider-container .slider').slick({
    	'autoplay' : true,
    	'slide' : '.item',
    	'slidesToShow' : Math.max($('.item').length, 6),
    	'slidesToScroll' : 1,
    	'prevArrow' : '<button type="button" class="slick-prev"><i class="icon-arrow-left-empty"></i></button>',
    	'nextArrow' : '<button type="button" class="slick-next"><i class="icon-arrow-right-empty"></i></button>',
    	'responsive' : [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    });


})